// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.product-card {
    @extend .card, .shadow, .text-bg-light, .h-100, .bg-white;
    transition: 0.3s;

    &:hover {
        transform: translate(0, -10px);
    }
}

.flex-overflow-ellipsis {
    // to be used with text-truncate on the same element
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

$ot-blue: #204CA3;

:root {
    --ot-blue: #{$ot-blue};
}

$text-truncate-limits: 5;

// line clamp 1-5 lines. truncates text
@mixin make-text-truncate($size: false, $i) {
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
}

@each $bp in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($bp, $grid-breakpoints);

    @include media-breakpoint-up($bp) {
        @for $i from 1 through $text-truncate-limits {
            .text-truncate#{$infix}-#{$i} {
                @include make-text-truncate($bp, $i);
            }
        }
    }
}


.dropdown-menu {
    z-index: 1025 !important;
    // fix dropdown under sticky. default bs value: 1000
}

.text-prewrap {
    white-space: pre-wrap;
}

.text-preline {
    white-space: pre-line;
}

.text-justify {
    text-align: justify;
}

.bg-purple {
    --bs-bg-opacity: 1;
    background-color: var(--bs-purple) !important;
}

.mh-25vh {
    max-height: 25vh;
}

.mh-50vh {
    max-height: 50vh;
}
